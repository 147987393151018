/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
];

//Development
// export const firebaseConfig = {  
//   databaseURL: 'https://gestor-de-resultados-default-rtdb.firebaseio.com',
//   apiKey: "AIzaSyAEhlB2-F7mSieBAIAiIP1Dl8egDcwWMQs",
//   authDomain: "gestor-de-resultados.firebaseapp.com",
//   projectId: "gestor-de-resultados",
//   storageBucket: "gestor-de-resultados.appspot.com",
//   messagingSenderId: "703914623741",
//   appId: "1:703914623741:web:9de90f23116f6eaef2f494"  
// };

//Production
export const firebaseConfig = {
  apiKey: 'AIzaSyBy_1sM0l0FLaUD9pgrXyttkn_F-j9mv1A',
  authDomain: 'juanola-5f521.firebaseapp.com',
  databaseURL: 'https://juanola-5f521.firebaseio.com',
  projectId: 'juanola-5f521',
  storageBucket: 'juanola-5f521.appspot.com',
  messagingSenderId: '891707796897',
  appId: '1:891707796897:web:b8f13fe49f16a33ec72fe0',
};

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = 'light.purple';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
