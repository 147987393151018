import { LOGIN_USER_SUCCESS } from '../actions';

const INIT_STATE = {
  ids: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ids: action.payload.institutions,
      };
    default:
      return { ...state };
  }
};
