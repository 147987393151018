import { ADD_EXAM_RESULTS, ADD_EXAM_OVERVIEW } from '../actions';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_EXAM_RESULTS: {
      const { key, ...rest } = action.payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          users: { ...state[key]?.users, ...rest },
        },
      };
    }
    case ADD_EXAM_OVERVIEW: {
      const { key, ...rest } = action.payload;
      return {
        ...state,
        [key]: {
          ...rest[key],
          users: { ...state[key]?.users },
        },
      };
    }
    default:
      return { ...state };
  }
};
