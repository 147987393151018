import { BASIC_USER } from '../../constants/userTypes';
import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  FETCH_USER_DATA_SUCCESS,
} from '../actions';

const INIT_STATE = {
  userId: localStorage.getItem('user_id'),
  displayName: localStorage.getItem('display_name'),
  photoURL: localStorage.getItem('photo_url'),
  userType: BASIC_USER,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        userId: action.payload.uid,
        userType: action.payload.userType,
        error: '',
      };
    case LOGOUT_USER:
      return {
        ...INIT_STATE,
        userId: null,
        displayName: '',
        photoURL: '',
      };
    case FETCH_USER_DATA_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    default:
      return { ...state };
  }
};
