/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* APP */
export const FETCH_ALL_EXAMS_FOR_INSTITUTION =
  'FETCH_ALL_EXAMS_FOR_INSTITUTION';
export const FETCH_ALL_EXAMS_FOR_INSTITUTION_SUCCESS =
  'FETCH_ALL_EXAMS_FOR_INSTITUTION_SUCCESS';
export const FETCH_ALL_EXAMS_FOR_INSTITUTION_ERROR =
  'FETCH_ALL_EXAMS_FOR_INSTITUTION_ERROR';

export const FETCH_ALL_EXAMS_FOR_USER = 'FETCH_ALL_EXAMS_FOR_USER';
export const FETCH_ALL_EXAMS_FOR_USER_SUCCESS =
  'FETCH_ALL_EXAMS_FOR_USER_SUCCESS';
export const FETCH_ALL_EXAMS_FOR_USER_ERROR = 'FETCH_ALL_EXAMS_FOR_USER_ERROR';

export const FETCH_USER_EXAM_RESULT = 'FETCH_USER_EXAM_RESULT';
export const FETCH_USER_EXAM_RESULT_SUCCESS = 'FETCH_USER_EXAM_RESULT_SUCCESS';
export const FETCH_USER_EXAM_RESULT_ERROR = 'FETCH_USER_EXAM_RESULT_ERROR';

export const FETCH_ALL_RESULTS_FOR_EXAM = 'FETCH_ALL_RESULTS_FOR_EXAM';
export const FETCH_ALL_RESULTS_FOR_EXAM_SUCCESS =
  'FETCH_ALL_RESULTS_FOR_EXAM_SUCCESS';
export const FETCH_ALL_RESULTS_FOR_EXAM_ERROR =
  'FETCH_ALL_RESULTS_FOR_EXAM_ERROR';

export const FETCH_EXAM_OVERVIEW = 'FETCH_EXAM_OVERVIEW';
export const FETCH_EXAM_OVERVIEW_SUCCESS = 'FETCH_EXAM_OVERVIEW_SUCCESS';
export const FETCH_EXAM_OVERVIEW_ERROR = 'FETCH_EXAM_OVERVIEW_ERROR';

export const ADD_EXAM_RESULTS = 'ADD_EXAM_RESULTS';
export const ADD_EXAM_OVERVIEW = 'ADD_EXAM_OVERVIEW';

export * from './exams/actions';
export * from './user/actions';
export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
