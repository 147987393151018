import { database } from '../../helpers/Firebase';
import {
  ADD_EXAM_OVERVIEW,
  ADD_EXAM_RESULTS,
  FETCH_ALL_EXAMS_FOR_INSTITUTION,
  FETCH_ALL_EXAMS_FOR_INSTITUTION_ERROR,
  FETCH_ALL_EXAMS_FOR_INSTITUTION_SUCCESS,
  FETCH_ALL_EXAMS_FOR_USER,
  FETCH_ALL_EXAMS_FOR_USER_ERROR,
  FETCH_ALL_EXAMS_FOR_USER_SUCCESS,
  FETCH_ALL_RESULTS_FOR_EXAM,
  FETCH_ALL_RESULTS_FOR_EXAM_ERROR,
  FETCH_ALL_RESULTS_FOR_EXAM_SUCCESS,
  FETCH_EXAM_OVERVIEW,
  FETCH_EXAM_OVERVIEW_ERROR,
  FETCH_EXAM_OVERVIEW_SUCCESS,
  FETCH_USER_EXAM_RESULT,
  FETCH_USER_EXAM_RESULT_ERROR,
  FETCH_USER_EXAM_RESULT_SUCCESS,
} from '../actions';

export const fetchAllExamResults = (examId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_RESULTS_FOR_EXAM, payload: examId });
    // get exams details for an examId
    database
      .ref(`/results/${examId}`)
      .once('value')
      .then((snapshot) => {
        dispatch({
          type: FETCH_ALL_RESULTS_FOR_EXAM_SUCCESS,
          payload: snapshot,
        });
        snapshot.forEach((examSnapshot) => {
          dispatch({
            type: ADD_EXAM_RESULTS,
            payload: { key: examId, [examSnapshot.key]: examSnapshot.val() },
          });
        });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_ALL_RESULTS_FOR_EXAM_ERROR,
          payload: error,
        }),
      );
  };
};

export const fetchAllExamsForUser = (userId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_EXAMS_FOR_USER, payload: { userId } });
    //all exams with the userId
    database
      .ref(`/exams`)
      .orderByChild(`/users/${userId}`)
      .startAt(0)
      .once('value')
      .then((examListingSnapshot) => {
        dispatch({
          type: FETCH_ALL_EXAMS_FOR_USER_SUCCESS,
          payload: examListingSnapshot,
        });
        examListingSnapshot.forEach((examSnapshot) => {
          dispatch(addExamOverview(examSnapshot.key, examSnapshot.val()));
        });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_ALL_EXAMS_FOR_USER_ERROR,
          payload: error,
        }),
      );
  };
};

const addExamOverview = (key, value) => {
  // eslint-disable-next-line no-unused-vars
  const { users, ...rest } = value;
  return {
    type: ADD_EXAM_OVERVIEW,
    payload: { key, [key]: { id: key, ...rest } },
  };
};

export const fetchExamInfo = (examId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_EXAM_OVERVIEW, payload: { examId } });
    //all exams with the examId
    database
      .ref(`/exams/${examId}`)
      .once('value')
      .then((examListingSnapshot) => {
        dispatch({
          type: FETCH_EXAM_OVERVIEW_SUCCESS,
          payload: examListingSnapshot,
        });
        dispatch(
          addExamOverview(examListingSnapshot.key, examListingSnapshot.val()),
        );
      })
      .catch((error) =>
        dispatch({
          type: FETCH_EXAM_OVERVIEW_ERROR,
          payload: error,
        }),
      );
  };
};

export const fetchResultsForExamAndUser = (examId, userId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_EXAM_RESULT, payload: { userId, examId } });
    // get exams details for an userId
    database
      .ref(`/results/${examId}/${userId}`)
      .once('value')
      .then((snapshot) => {
        dispatch({
          type: FETCH_USER_EXAM_RESULT_SUCCESS,
          payload: snapshot.val(),
        });
        dispatch({
          type: ADD_EXAM_RESULTS,
          payload: { key: examId, [userId]: snapshot.val() },
        });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_USER_EXAM_RESULT_ERROR,
          payload: error,
        }),
      );
  };
};

export const fetchAllInstitutionExams = (institutionId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_EXAMS_FOR_INSTITUTION, payload: institutionId });
    // get exams by UID
    database
      .ref('/exams')
      .orderByChild('company')
      .equalTo(institutionId)
      .once('value')
      .then((examListingSnapshot) => {
        dispatch({
          type: FETCH_ALL_EXAMS_FOR_INSTITUTION_SUCCESS,
          payload: examListingSnapshot,
        });
        examListingSnapshot.forEach((examSnapshot) => {
          dispatch(addExamOverview(examSnapshot.key, examSnapshot.val()));
        });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_ALL_EXAMS_FOR_INSTITUTION_ERROR,
          payload: error,
        }),
      );
  };
};
