import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import institutions from './institutions/reducer';
import exams from './exams/reducer';
import user from './user/reducer';
import { LOGOUT_USER } from './actions';

const reducers = combineReducers({
  exams,
  institutions,
  user,
  menu,
  settings,
  authUser,
});

const secureReducer = (state, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      reducers(state, action); // just in case we want to do something at log out.
      return reducers(undefined, action);
    }
    default:
      return reducers(state, action);
  }
};

export default secureReducer;
