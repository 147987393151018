import { auth, database } from '../../helpers/Firebase';
import { BASIC_USER, INSTITUTION_ADMIN } from '../../constants/userTypes';
import {
  FETCH_USER_DATA_SUCCESS,
  loginUserSuccess,
  logoutUser,
} from '../actions';

export const fetchUserDataSuccess = (userData) => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload: userData,
});

export const startListeningToAuthChanges = () => {
  return (dispatch) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        database
          .ref(`/admins/${user.uid}`)
          .once('value')
          .then((snapshot) => {
            const institutions = snapshot.val();
            dispatch(
              loginUserSuccess({
                ...user,
                institutions: (institutions && Object.keys(institutions)) || [],
                userType: institutions ? INSTITUTION_ADMIN : BASIC_USER,
              }),
            );
          });
      } else {
        dispatch(logoutUser());
      }
    });
  };
};
