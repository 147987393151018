import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { database } from '../../helpers/Firebase';
import { LOGIN_USER_SUCCESS } from '../actions';

import { fetchUserDataSuccess } from './actions';

export function* watchLoginUserSuccess() {
  yield takeEvery(LOGIN_USER_SUCCESS, fetchUserData);
}

const getUserInfo = async ({ uid }) =>
  await database
    .ref(`/users/${uid}`)
    .once('value')
    .then((snapshot) => ({ uid, ...snapshot.val() }));

function* fetchUserData({ payload }) {
  try {
    const userData = yield call(getUserInfo, payload);
    const { displayName, photoURL } = userData;
    localStorage.setItem('display_name', displayName);
    localStorage.setItem('photo_url', photoURL);
    yield put(fetchUserDataSuccess(userData));
  } catch {}
}

export default function* rootSaga() {
  yield all([fork(watchLoginUserSuccess)]);
}
